<template>
  <Transition name="expand"
              @enter="onEnter"
              @after-enter="onAfterEnter"
              @leave="onLeave"
  >
    <slot />
  </Transition>
</template>

<script setup lang="ts">
function onEnter(element: HTMLElement) {
  const width = getComputedStyle(element).width

  element.style.width = width
  element.style.position = 'absolute'
  element.style.visibility = 'hidden'
  element.style.height = 'auto'

  const height = getComputedStyle(element).height

  element.style.width = null
  element.style.position = null
  element.style.visibility = null
  element.style.height = '0'

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = height
  })
}

function onAfterEnter(element: HTMLElement) {
  element.style.height = 'auto'
}

function onLeave(element: HTMLElement) {
  const height = getComputedStyle(element).height

  element.style.height = height

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height

  requestAnimationFrame(() => {
    element.style.height = '0'
  })
}
</script>

<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
  @apply overflow-hidden ease-in-out duration-500 transition-all;
}

.expand-enter,
.expand-leave-to {
  @apply h-0 opacity-0;
}
</style>
